import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

// Converts a Date object to a string in the format "mm-dd-yyyy"
function formatDateAsString(date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

// Connects to data-controller="admin-order-edit"
export default class extends Controller {
  static get values() {
    return {
      orderId: String,
    };
  }

  static get targets() {
    return [
      'lineItem',
      'deliveryDate',
      'deliveryZipCode',
      'skipFulfillmentChecks',
      'allowDifferentFulfillmentCenters',
    ];
  }

  connect() {
    this.unavailableDates = [];
  }

  /**
   * Debounces the updateAvailableDates function to avoid too many requests
   */
  debounceUpdateAvailableDates() {
    if (!this.debounceUpdateAvailableDatesFunc) {
      this.debounceUpdateAvailableDatesFunc = debounce(() => {
        this.updateAvailableDates();
      }, 200);
    }
    this.debounceUpdateAvailableDatesFunc();
  }

  lineItemTargetConnected(target) {
    this.debounceUpdateAvailableDates();
  }

  /**
   * @param {Element} target
   */
  deliveryDateTargetConnected(target) {
    $(target).datepicker({
      dateFormat: 'mm-dd-yy',
      defaultDate: '+1d',
      minDate: +1,
      maxDate: '+1Y',
      showDropdowns: true,
      beforeShowDay: this.beforeShowDay.bind(this),
      onSelect: this.onSelect.bind(this),
    });
    this.debounceUpdateAvailableDates();
  }

  /**
   * @param {Element} target
   */
  deliveryZipCodeTargetConnected(target) {
    target.addEventListener('change', () => {
      this.debounceUpdateAvailableDates();
    });
  }

  /**
   * @param {Element} target
   */
  skipFulfillmentChecksTargetConnected(target) {
    target.addEventListener('change', () => {
      this.debounceUpdateAvailableDates();
    });
  }

  /**
   * @param {Element} target
   */
  allowDifferentFulfillmentCentersTargetConnected(target) {
    target.addEventListener('change', () => {
      this.debounceUpdateAvailableDates();
    });
  }

  /**
   * @see https://api.jqueryui.com/datepicker/#option-beforeShowDay
   */
  beforeShowDay(date) {
    if (this.unavailableDates.indexOf(formatDateAsString(date)) !== -1) {
      return [false, 'ui-disabled-red', 'Sold Out/Not Available'];
    }
    return [true, ''];
  }

  /**
   * @see https://api.jqueryui.com/datepicker/#option-onSelect
   */
  onSelect(dateText, instance) {
    // dateText is the date formatted as "mm-dd-yyyy"
    const [month, day, year] = dateText.split('-');
    const date = new Date(
      year,
      month - 1, // month is zero-indexed
      day,
    );
    if (this.unavailableDates.indexOf(formatDateAsString(date)) > -1) {
      $('p.error').show();
    } else {
      $('p.error').hide();
    }
  }

  updateAvailableDates() {
    const deliveryDateInputs = this.deliveryDateTargets;
    deliveryDateInputs.forEach((element) => {
      // Disable while loading
      element.disabled = true;
    });
    const attrsForURLSearchParams = {};
    this.getProductIdsWithQty()
      .entries()
      .forEach(([ix, { item_id, qty }]) => {
        attrsForURLSearchParams[`product_ids_qty[${ix}][item_id]`] = item_id;
        attrsForURLSearchParams[`product_ids_qty[${ix}][qty]`] = qty;
      });

    if (this.deliveryZipCodeTarget) {
      attrsForURLSearchParams['delivery_zip_code'] =
        this.deliveryZipCodeTarget.value;
    }
    if (this.skipFulfillmentChecksTarget) {
      attrsForURLSearchParams['skip_fulfillment_checks'] = this
        .skipFulfillmentChecksTarget.checked
        ? '1'
        : '0';
    }
    if (this.allowDifferentFulfillmentCentersTarget) {
      attrsForURLSearchParams['allow_different_fulfillment_centers'] = this
        .allowDifferentFulfillmentCentersTarget.checked
        ? '1'
        : '0';
    }
    const queryString = new URLSearchParams(attrsForURLSearchParams);
    const promise = fetch(
      `/admin/sales_orders/${this.orderIdValue}/availability.json?${queryString}`,
    );
    promise
      .then((response) => response.json())
      .then((response) => {
        this.unavailableDates = [];
        response.unavailable_dates.forEach((dateStr) => {
          const date = new Date(dateStr); // ISO date format (YYYY-MM-DD)
          this.unavailableDates.push(formatDateAsString(date));
        });
      })
      .finally(() => {
        deliveryDateInputs.forEach((element) => {
          // Enable after loading
          element.disabled = false;
        });
      });
  }

  getProductIdsWithQty() {
    const items = [];
    this.lineItemTargets.forEach((element) => {
      const { productId, qty } = element.dataset;
      items.push({
        // TODO: Make the product_id, requires backend refactoring
        item_id: productId,
        qty,
      });
    });
    return items;
  }
}

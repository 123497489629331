import React, { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import Image from 'ui-components/Image';
import ProductBadge from 'components/ProductBadge';
import { getDiscount } from 'utils/getSubtotal';
import { pushMetricsEvent } from 'hooks/useDataLayer';
import { isFeatureEnabled } from 'utils/featureFlags';
import { QuickBuy } from '../QuickBuy';

import { getProductDateText } from './helpers/getProductDateText';
import { ProductCardProps } from './interface';
import {
  ProductCardInfo,
  ProductImage,
  StyledNumberFormat,
  StyledDiscountNumberFormat,
  ProductTypographySmall,
  ProductTypographySmall2,
  DetailsButtonStyled,
} from './styled';

const ProductCard: FC<ProductCardProps> = ({
  product,
  discountPrice,
  deliveryDate,
  groupingEnabled,
}) => {
  const showGrouped = groupingEnabled && product.grouping_id;

  const displayName = showGrouped ? product.grouping_name : product.name;
  const displayTagline = showGrouped
    ? product.grouping_tagline
    : product.tagline;
  const displaySlug = showGrouped ? product.grouping_slug : product.slug;
  const displayMainImage = showGrouped
    ? product.grouping_main_image
    : product.main_image;
  const displayPrice = showGrouped
    ? product.grouping_from_price
    : product.price;

  const [mouseIn, setMouseIn] = useState(false);
  const [mouseOverImage, setMouseOverImage] = useState(false);

  const mouseEnterHandler = () => {
    setMouseIn(true);
  };

  const mouseLeaveHandler = () => {
    setMouseIn(false);
  };

  const pushSelectItemEvent = useCallback(() => {
    pushMetricsEvent('ga4', {
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: product.id.toString(),
            item_name: product.name.trim(),
            discount: getDiscount(product.price, discountPrice),
            item_category: 'main_product',
            price: Number(product.price),
            quantity: 1,
          },
        ],
      },
    });
  }, [product, discountPrice]);

  return (
    <article
      className="w-full"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <a href={`/${displaySlug}`} onClick={pushSelectItemEvent}>
        <ProductImage
          onMouseEnter={() => setMouseOverImage(true)}
          onMouseLeave={() => setMouseOverImage(false)}
        >
          <Image
            src={displayMainImage?.cloudinary_photo_id || ''}
            alt={displayName!}
            width="100%"
            useWebP
            isSquare
          />
          {!product.grouping_id && product.promo_label ? (
            <ProductBadge label={product.promo_label} />
          ) : null}
          {isFeatureEnabled('quickBuy') &&
          dayjs(product.soonest_delivery_date).isSame(dayjs(deliveryDate)) ? (
            <QuickBuy
              product={product}
              deliveryDate={deliveryDate}
              visible={mouseOverImage}
            />
          ) : null}
        </ProductImage>
        <ProductCardInfo className="my-3">
          <h3 className="!font-brandon !m-0 overflow-hidden overflow-ellipsis whitespace-nowrap !text-xl !font-medium !leading-6 !text-black">
            {displayName}
          </h3>
          {displayTagline ? (
            <ProductTypographySmall variant="subtitle2">
              {displayTagline}
            </ProductTypographySmall>
          ) : (
            <div className="h-8" />
          )}
          <ProductTypographySmall2 variant="body2">
            {product.soonest_delivery_date && (
              <i>
                {getProductDateText(
                  product.soonest_delivery_date,
                  deliveryDate,
                )}
              </i>
            )}
          </ProductTypographySmall2>
        </ProductCardInfo>
      </a>
      <DetailsButtonStyled
        href={`/${displaySlug}`}
        onClick={pushSelectItemEvent}
        mousein={mouseIn ? 'true' : ''}
        color="primary"
        variant="contained"
        fullWidth
      >
        View Details - {showGrouped ? 'From ' : null}
        {!showGrouped && discountPrice && discountPrice !== displayPrice ? (
          <>
            <StyledNumberFormat
              value={Number(displayPrice).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
              isdiscounted="true"
            />
            <StyledDiscountNumberFormat
              value={Number(discountPrice).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </>
        ) : (
          <StyledNumberFormat
            value={Number(displayPrice).toFixed(2)}
            displayType="text"
            thousandSeparator
            prefix="$"
            isdiscounted=""
          />
        )}
      </DetailsButtonStyled>
    </article>
  );
};

export default ProductCard;

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return [
      'form',
      'payNowBtn',
      'confirmationPopup',
      'confirmSubmitBtn',
      'cancelSubmitBtn',
    ];
  }

  connect() {
    this.confirmationPopupTarget.classList.add('hidden');
  }

  confirmSubmit() {
    this.hidePopup();
    this.addAdditionalParam('checkout', '1');
    this.formTarget.submit();
  }

  cancelSubmit() {
    this.hidePopup();
    this.payNowBtnTarget.disabled = false;
  }

  addAdditionalParam(name, value) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = name;
    hiddenInput.value = value;
    this.formTarget.appendChild(hiddenInput);
  }

  hidePopup() {
    event.preventDefault();
    this.confirmationPopupTarget.classList.add('hidden');
  }

  showPopup() {
    event.preventDefault();
    this.payNowBtnTarget.disabled = true;
    this.confirmationPopupTarget.classList.remove('hidden');
  }
}

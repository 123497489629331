import ReactOnRails from 'react-on-rails';

import AboutUs from 'pages/about';
import Addons from 'components/Addons';
import AddressDeleteConfirmation from 'components/Modals/AddressDeleteConfirmation';
import Cart from 'pages/Cart';
import CartAddressVerificationModal from 'components/Modals/CartAddressVerification';
import CartCalendar from 'components/Checkout/CartCalendar';
import CorporateGifting from 'pages/CorporateGifting';
import CustomerAddressVerificationModal from 'components/Modals/CustomerAddressVerification';
import DeliveryDateControl from 'components/Checkout/DeliveryDateControl';
import DeliveryWindowPopup from 'components/DeliveryWindowPopup/index';
import Footer from 'components/Footer';
import Gallery from 'components/Gallery';
import InfoPopover from 'components/InfoPopover';
import OrderConfirmation from 'pages/OrderConfirmation';
import PaymentDeleteConfirmation from 'components/Modals/PaymentDeleteConfirmation';
import PDP from 'pages/pdp';
import ProductForm from 'components/ProductForm';
import SavedAddressVerificationModal from 'components/Modals/SavedAddressVerification';
import ShippingPopup from 'components/ShippingPopup/index';
import Shop from 'pages/shop';
import StickyCheckoutButton from 'components/StickyCheckoutButton';

import '../../setup_turbo';
import '../../controllers';

ReactOnRails.register({
  AboutUs,
  Addons,
  AddressDeleteConfirmation,
  Cart,
  CartAddressVerificationModal,
  CartCalendar,
  SavedAddressVerificationModal,
  CorporateGifting,
  CustomerAddressVerificationModal,
  DeliveryDateControl,
  DeliveryWindowPopup,
  Footer,
  Gallery,
  InfoPopover,
  OrderConfirmation,
  PaymentDeleteConfirmation,
  PDP,
  ProductForm,
  ShippingPopup,
  Shop,
  StickyCheckoutButton,
});

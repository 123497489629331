import { Turbo } from '@hotwired/turbo-rails';
import { StreamActions } from '@hotwired/turbo';
import { scrollToError } from './lib/dom_utils';

// Make Drive to be opt-in, so data-turbo="true" is used to enable Drive on a
// per-element basis.
Turbo.session.drive = false;

// Prevent breaking of existing forms by explicitly requiring opt-in via
// data-turbo="true".
Turbo.setFormMode('optin');

StreamActions.addClass = function () {
  this.targetElements.forEach((element) => {
    element.classList.add(this.templateElement.innerHTML.trim());
  });
};

StreamActions.appendClass = function () {
  document.querySelectorAll(this.target).forEach((element) => {
    element.classList.add(this.templateElement.innerHTML.trim());
  });
};

StreamActions.removeClass = function () {
  document.querySelectorAll(this.target).forEach((element) => {
    element.classList.remove(this.templateElement.innerHTML.trim());
  });
};

StreamActions.removeElement = function () {
  document.querySelectorAll(this.target).forEach((element) => {
    element.remove();
  });
};

StreamActions.scrollToError = function () {
  scrollToError();
};

StreamActions.toggle = function() {
  const visible = this.getAttribute("visible");
  this.targetElements.forEach((element) => {
    if (visible === "true") {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  });
}

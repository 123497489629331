import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.onchange = () => {
      if (this.element.requestSubmit) {
        this.element.requestSubmit();
      } else {
        this.element.closest('form').requestSubmit();
      }
    };
  }
}

import React from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { ApiRoutes } from 'constants/api';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/time';
import { Button } from 'ui-components/v2/Button';
import { QuickBuyProps } from 'pages/shop/Results/ProductCard/interface';

export const QuickBuy = ({ product, deliveryDate, visible }: QuickBuyProps) => {
  const { post, loading } = useFetch<{ status: string }>(ApiRoutes.Cart, {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      'X-CSRF-Token':
        typeof window !== 'undefined' ? window.FORM_AUTHENTICITY_TOKEN : '', // "typeof window ..." is needed for SSR
    },
  });

  const addToCart = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    const date = dayjs(deliveryDate).format(DATE_FORMAT);
    post({
      product_id: product.id,
      quantity: 1,
      date,
    }).then(() => {
      window.location.assign(ApiRoutes.InterstitialAddons);
    });
  };

  return (
    <div
      className={`visible absolute bottom-0 w-full bg-white opacity-85 duration-500 ${!visible ? 'md:invisible md:opacity-0' : ''}`}
    >
      <Button
        id="quick-buy-button"
        className="tracking-add-to-cart"
        fullWidth
        color="secondary"
        disabled={loading}
        onClick={addToCart}
      >
        Quick Buy
      </Button>
    </div>
  );
};
